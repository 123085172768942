
import { defineComponent, ref, watch } from 'vue'
import TmColorPalette from '@/components/shared/TmColorPalette.vue'
import { colorPaletteOptionsFilled } from '@/definitions/_general/_data/colorPaletteOptions'
import TmColorIndicator from '@/components/shared/TmColorIndicator.vue'

export default defineComponent({
  components: {
    TmColorIndicator,
    TmColorPalette,
  },
  setup() {
    const colorTheme = ref('gradient')
    const color = ref(colorPaletteOptionsFilled[0])
    const isGradient = ref(true)

    const checkGradient = (val: boolean) => {
      isGradient.value = val
    }

    watch(() => colorTheme.value, (val) => {
      checkGradient(val === 'gradient')
    })

    return {
      colorTheme,
      color,
      isGradient,
      colorPaletteOptionsFilled,
    }
  },
})
